<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasTextImage = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasTextImage;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("maasTextImage");

const { getConfigValue } = useCmsElementConfig(slot);
const content: any = getConfigValue("objItems");

function getImageSize(columns:number):number {
  switch (columns) {
    case 2:
      return 750;
    case 3:
      return 485;
    default:
      return 1520;
  }
}
</script>

<template>
  <div class="s-gutter" v-if="content.length >= 1">
    <div
      class="grid grid-cols-1 gap-8"
      :class="{
        'c-width-default': slot?.config?.contentWidth?.value === 'full',
        'c-width-narrow': slot?.config?.contentWidth?.value === 'narrow',
        'c-width-small': slot?.config?.contentWidth?.value === 'small',
        'md:grid-cols-2':
          content.length === 2 && (slot?.config?.layout.value === '' || slot?.config?.layout.value === '1:1'),
        'md:grid-cols-[minmax(0,1fr)_minmax(0,2fr)]': content.length === 2 && slot?.config?.layout.value === '1:2',
        'md:grid-cols-[minmax(0,2fr)_minmax(0,1fr)]': content.length === 2 && slot?.config?.layout.value === '2:1',
        'md:grid-cols-[minmax(0,1fr)_minmax(0,3fr)]': content.length === 2 && slot?.config?.layout.value === '1:3',
        'md:grid-cols-[minmax(0,3fr)_minmax(0,1fr)]': content.length === 2 && slot?.config?.layout.value === '3:1',
        'md:grid-cols-2 lg:grid-cols-3':
          content.length === 3 && (slot?.config?.layout.value === '' || slot?.config?.layout.value === '1:1:1'),
        'md:grid-cols-2 lg:grid-cols-[minmax(0,2fr)_repeat(2,minmax(0,1fr))]':
          content.length === 3 && slot?.config?.layout.value === '2:1:1',
        'md:grid-cols-2 lg:grid-cols-[minmax(0,1fr)_minmax(0,2fr)_minmax(0,1fr)]':
          content.length === 3 && slot?.config?.layout.value === '1:2:1',
        'md:grid-cols-2 lg:grid-cols-[repeat(2,minmax(0,1fr))_minmax(0,2fr)]':
          content.length === 3 && slot?.config?.layout.value === '1:1:2',
      }"
    >
      <CmsElementMaasTextImage
        v-for="element in content"
        :key="element.sortId"
        :text="element.text"
        :text-style="slot?.config?.textStyle?.value"
        :class="element.position === 'below' ? 'flex-col-reverse justify-end' : 'flex-col'"
      >
        <div v-if="element?.media?.id">
          <SharedCmsImage
            :src="element?.media?.url"
            :crop="element?.media?.crop"
            :focus-point="element?.media?.focusPoint"
            :alt="element.caption"
            :width="getImageSize(content.length)"
            :class-img="{
              'aspect-square': element?.aspectRatio === '1x1',
              'aspect-video': element?.aspectRatio === '16x9',
              'aspect-2/3': element?.aspectRatio === '2x3',
            }"
          />
          <p class="text-maas-typography-text-grey-medium mt-2 italic">{{ element.caption }}</p>
        </div>
      </CmsElementMaasTextImage>
    </div>
  </div>
</template>
